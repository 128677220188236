// Generated by Framer (d19f530)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sEi_c9524", "BP5mnHqo1", "JX34mHuhv", "f6NLbbCoA", "yin2H5Ct2", "PO7EVO3mz", "omG32AEKk", "IkIkfAHER", "nRFhRcQks", "C6juS6sV8", "hMsMjDg1b", "PTS_5sSDF", "R8FBLS0Lh", "YU4rYCgtM", "nIMzKq0Ft"];

const variantClassNames = {BP5mnHqo1: "framer-v-nzk92g", C6juS6sV8: "framer-v-xcr7n9", f6NLbbCoA: "framer-v-6niutn", hMsMjDg1b: "framer-v-1famtyp", IkIkfAHER: "framer-v-1rb4t6w", JX34mHuhv: "framer-v-1kbcnok", nIMzKq0Ft: "framer-v-1f1wuzy", nRFhRcQks: "framer-v-qkxuhx", omG32AEKk: "framer-v-1g4ntv9", PO7EVO3mz: "framer-v-1kzc8uq", PTS_5sSDF: "framer-v-1jcybip", R8FBLS0Lh: "framer-v-13uuppv", sEi_c9524: "framer-v-1dixt8c", yin2H5Ct2: "framer-v-sz24iz", YU4rYCgtM: "framer-v-1u9l4fv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"2XL": "hMsMjDg1b", "2XS": "yin2H5Ct2", "3XL": "C6juS6sV8", "3XS": "f6NLbbCoA", "4XL": "R8FBLS0Lh", "4XS": "JX34mHuhv", "5XL": "YU4rYCgtM", "5XS": "BP5mnHqo1", "6XL": "nIMzKq0Ft", "6XS": "sEi_c9524", L: "nRFhRcQks", M: "IkIkfAHER", S: "omG32AEKk", XL: "PTS_5sSDF", XS: "PO7EVO3mz"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "sEi_c9524", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sEi_c9524", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-J8FDA", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1dixt8c", className)} data-framer-name={"6XS"} layoutDependency={layoutDependency} layoutId={"sEi_c9524"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({BP5mnHqo1: {"data-framer-name": "5XS"}, C6juS6sV8: {"data-framer-name": "3XL"}, f6NLbbCoA: {"data-framer-name": "3XS"}, hMsMjDg1b: {"data-framer-name": "2XL"}, IkIkfAHER: {"data-framer-name": "M"}, JX34mHuhv: {"data-framer-name": "4XS"}, nIMzKq0Ft: {"data-framer-name": "6XL"}, nRFhRcQks: {"data-framer-name": "L"}, omG32AEKk: {"data-framer-name": "S"}, PO7EVO3mz: {"data-framer-name": "XS"}, PTS_5sSDF: {"data-framer-name": "XL"}, R8FBLS0Lh: {"data-framer-name": "4XL"}, yin2H5Ct2: {"data-framer-name": "2XS"}, YU4rYCgtM: {"data-framer-name": "5XL"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-J8FDA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-J8FDA .framer-6yt8fk { display: block; }", ".framer-J8FDA .framer-1dixt8c { height: 2px; position: relative; width: 2px; }", ".framer-J8FDA.framer-v-nzk92g .framer-1dixt8c { height: 4px; width: 4px; }", ".framer-J8FDA.framer-v-1kbcnok .framer-1dixt8c { height: 8px; width: 8px; }", ".framer-J8FDA.framer-v-6niutn .framer-1dixt8c { height: 12px; width: 12px; }", ".framer-J8FDA.framer-v-sz24iz .framer-1dixt8c { height: 16px; width: 16px; }", ".framer-J8FDA.framer-v-1kzc8uq .framer-1dixt8c { height: 24px; width: 24px; }", ".framer-J8FDA.framer-v-1g4ntv9 .framer-1dixt8c { height: 32px; width: 32px; }", ".framer-J8FDA.framer-v-1rb4t6w .framer-1dixt8c { height: 40px; width: 40px; }", ".framer-J8FDA.framer-v-qkxuhx .framer-1dixt8c { height: 48px; width: 48px; }", ".framer-J8FDA.framer-v-xcr7n9 .framer-1dixt8c { height: 80px; width: 80px; }", ".framer-J8FDA.framer-v-1famtyp .framer-1dixt8c { height: 64px; width: 64px; }", ".framer-J8FDA.framer-v-1jcybip .framer-1dixt8c { height: 56px; width: 56px; }", ".framer-J8FDA.framer-v-13uuppv .framer-1dixt8c { height: 96px; width: 96px; }", ".framer-J8FDA.framer-v-1u9l4fv .framer-1dixt8c { height: 120px; width: 120px; }", ".framer-J8FDA.framer-v-1f1wuzy .framer-1dixt8c { height: 160px; width: 160px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 2
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"BP5mnHqo1":{"layout":["fixed","fixed"]},"JX34mHuhv":{"layout":["fixed","fixed"]},"f6NLbbCoA":{"layout":["fixed","fixed"]},"yin2H5Ct2":{"layout":["fixed","fixed"]},"PO7EVO3mz":{"layout":["fixed","fixed"]},"omG32AEKk":{"layout":["fixed","fixed"]},"IkIkfAHER":{"layout":["fixed","fixed"]},"nRFhRcQks":{"layout":["fixed","fixed"]},"C6juS6sV8":{"layout":["fixed","fixed"]},"hMsMjDg1b":{"layout":["fixed","fixed"]},"PTS_5sSDF":{"layout":["fixed","fixed"]},"R8FBLS0Lh":{"layout":["fixed","fixed"]},"YU4rYCgtM":{"layout":["fixed","fixed"]},"nIMzKq0Ft":{"layout":["fixed","fixed"]}}}
 */
const FramerNHdRpOPd8: React.ComponentType<Props> = withCSS(Component, css, "framer-J8FDA") as typeof Component;
export default FramerNHdRpOPd8;

FramerNHdRpOPd8.displayName = "Elemental/ Spacing blocks";

FramerNHdRpOPd8.defaultProps = {height: 2, width: 2};

addPropertyControls(FramerNHdRpOPd8, {variant: {options: ["sEi_c9524", "BP5mnHqo1", "JX34mHuhv", "f6NLbbCoA", "yin2H5Ct2", "PO7EVO3mz", "omG32AEKk", "IkIkfAHER", "nRFhRcQks", "C6juS6sV8", "hMsMjDg1b", "PTS_5sSDF", "R8FBLS0Lh", "YU4rYCgtM", "nIMzKq0Ft"], optionTitles: ["6XS", "5XS", "4XS", "3XS", "2XS", "XS", "S", "M", "L", "3XL", "2XL", "XL", "4XL", "5XL", "6XL"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerNHdRpOPd8, [])